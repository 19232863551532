import React from "react";

export const MustacheThinBullConfig = {
    name: "Mustache Thin Bull",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 312,
    height: 230,
    viewBox: "0 0 312 230"
};

const MustacheThinBull = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M305.269 13.0049C301.955 8.55588 295.998 2.71688 289.972 3.03088C289.201 3.07088 288.912 3.90588 289.375 4.47088C291.346 6.87788 294.039 8.58988 296.014 11.0469C298.351 13.9549 300.038 17.4619 301.135 21.0089C302.729 26.1619 303.169 31.9939 301.748 37.2339C299.244 46.4699 291.327 53.2399 282.689 56.6969C274.139 60.1189 263.851 61.2879 255.765 56.2739C243.83 48.8719 233.707 38.2019 218.706 37.9909C215.197 37.9419 212.724 39.5489 211.251 41.8429C209.771 39.6309 207.329 38.0659 203.908 38.0269C187.091 37.8379 174.539 49.4149 160.424 56.6319C150.885 61.5099 138.542 59.9959 128.701 56.7729C119.418 53.7339 109.341 47.6309 105.839 37.9939C103.906 32.6719 104.339 26.4579 106.324 21.2119C107.629 17.7619 109.644 14.4289 112.137 11.7179C114.722 8.90588 118.12 7.13788 120.768 4.47188C121.309 3.92688 120.882 3.07988 120.171 3.03088C108.925 2.26788 98.7699 15.6959 96.6469 25.4379C94.5559 35.0319 97.6739 44.6009 103.985 51.9219C116.818 66.8059 140.58 71.7149 159.074 67.3409C174.043 63.8019 188.26 55.6499 203.908 56.8199C207.338 57.0769 209.8 55.6469 211.286 53.4789C212.774 55.7099 215.252 57.1689 218.706 56.8349C233.29 55.4219 246.307 65.7499 260.463 68.2719C271.734 70.2789 283.909 67.5719 293.567 61.4999C303.662 55.1529 310.768 44.9719 311.236 32.8339C311.505 25.8509 309.456 18.6259 305.269 13.0049Z'
                fill={outlineColor}
            />
        </>
    );
};

MustacheThinBull.config = MustacheThinBullConfig;
export default MustacheThinBull;

import React from "react";

export const GlassesConfig = {
    name: "Glasses",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 425,
    height: 138,
    viewBox: "0 0 425 138"
};

const Glasses = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M391.09 63.2822C381.556 127.771 315.002 134.903 303.177 70.8812C305.075 70.0502 306.44 68.4142 306.155 65.2382C306.088 51.2862 310.419 36.5402 323.005 28.7812C336.011 20.7752 351.232 19.9812 366.283 20.1832C395.005 19.4992 394.401 43.5062 391.09 63.2822ZM292.758 35.2352C283.744 34.8552 272.34 35.1732 264.265 40.6652C262.045 34.6872 256.277 28.3042 250.449 24.8912C272.111 25.5252 294.409 23.3932 316.233 21.7382C311.009 25.2992 306.149 30.6492 302.99 36.8342C300.059 35.2712 295.861 35.4172 292.758 35.2352ZM253.026 70.1192C228.031 156.155 165.64 118.655 158.838 107.629C146.569 89.3832 144.261 45.0292 154.948 33.1702C170.246 22.4462 191.903 25.2062 209.975 24.9432C243.176 25.3912 266.756 35.3232 253.026 70.1192ZM420.603 30.5482C413.812 29.6602 406.555 29.4812 400.241 32.4712C398.297 24.2082 392.194 17.3002 385.303 14.2602C384.469 13.2142 384.594 12.6522 381.745 12.8092C364.25 7.55124 344.673 10.3332 327.861 15.7252C301.309 17.9612 273.452 19.7242 246.677 22.8052C221.595 11.5412 145.902 9.89024 141.198 37.6532C135.281 36.7682 125.204 32.1112 120.232 38.4282C83.7606 32.2452 46.0756 40.8382 9.44663 44.6652C0.226627 46.3632 -4.52237 64.6612 5.94363 64.4112C11.8276 62.8142 5.76263 53.0172 13.6856 52.2802C49.0266 48.4202 83.5946 42.9442 119.298 42.3032C118.056 48.4682 142.912 48.8692 143.279 45.2692C143.332 45.2962 143.388 45.3142 143.442 45.3382C137.3 68.2282 142.844 101.17 153.944 115.364C169.496 133.95 208.541 139.282 225.854 127.324C248.187 116.287 271.42 60.8852 264.894 42.4792C276.738 38.7252 288.447 39.8522 300.739 41.7642C297.925 49.7132 293.067 69.2812 300.056 70.9782C298.773 109.828 334.13 134.069 366.724 118.132C394.935 104.889 404.801 62.9712 401.349 37.5282C404.861 44.3142 434.349 36.9262 420.603 30.5482Z'
                fill={outlineColor}
            />
        </>
    );
};

Glasses.config = GlassesConfig;

export default Glasses;

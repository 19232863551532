import React, { lazy, Suspense } from "react";

import GlassesThree from "./accessory/GlassesThree.js";
import GlassesFour from "./accessory/GlassesFour.js";
import GlassesFive from "./accessory/GlassesFive.js";
import SunglassesTwo from "./accessory/SunglassesTwo.js";
import GlassesSix from "./accessory/GlassesSix.js";
import GlassesTwo from "./accessory/GlassesTwo.js";
import Glasses from "./accessory/Glasses.js";
import Sunglasses from "./accessory/Sunglasses.js";
import Eyepatch from "./accessory/Eyepatch.js";

const accessoryComponents = {
    GlassesThree,
    GlassesFour,
    GlassesFive,
    SunglassesTwo,
    GlassesSix,
    GlassesTwo,
    Glasses,
    Sunglasses,
    Eyepatch,
};

import MustacheThin from "./beard/MustacheThin.js";
import FullColor from "./beard/FullColor.js";
import MustacheEight from "./beard/MustacheEight.js";
import GoateeFull from "./beard/GoateeFull.js";
import MustacheYosemite from "./beard/MustacheYosemite.js";
import MustacheSeven from "./beard/MustacheSeven.js";
import MustacheFive from "./beard/MustacheFive.js";
import MustacheThinBull from "./beard/MustacheThinBull.js";
import FullMedium from "./beard/FullMedium.js";
import MustacheBull from "./beard/MustacheBull.js";
import Goatee from "./beard/Goatee.js";
import Chin from "./beard/Chin.js";
import MustacheNine from "./beard/MustacheNine.js";
import MustacheSix from "./beard/MustacheSix.js";
import Full from "./beard/Full.js";
import FullMax from "./beard/FullMax.js";

const beardComponents = {
    MustacheThin,
    FullColor,
    MustacheEight,
    GoateeFull,
    MustacheYosemite,
    MustacheSeven,
    MustacheFive,
    MustacheThinBull,
    FullMedium,
    MustacheBull,
    Goatee,
    Chin,
    MustacheNine,
    MustacheSix,
    Full,
    FullMax,
};

import SweaterDots from "./body/effigy/SweaterDots.js";
import Paper from "./body/effigy/Paper.js";
import Whatever from "./body/effigy/Whatever.js";
import ThunderTee from "./body/effigy/ThunderTee.js";
import Coffee from "./body/effigy/Coffee.js";
import PolkadotJacket from "./body/effigy/PolkadotJacket.js";
import ButtonPocketShirt from "./body/effigy/ButtonPocketShirt.js";
import StripedTee from "./body/effigy/StripedTee.js";
import StrippedPocketTee from "./body/effigy/StrippedPocketTee.js";
import PointingUp from "./body/effigy/PointingUp.js";
import TeeSelena from "./body/effigy/TeeSelena.js";
import Jacket from "./body/effigy/Jacket.js";
import Explaining from "./body/effigy/Explaining.js";
import ButtonShirt from "./body/effigy/ButtonShirt.js";
import Gaming from "./body/effigy/Gaming.js";
import BlazerBlackTee from "./body/effigy/BlazerBlackTee.js";
import Turtleneck from "./body/effigy/Turtleneck.js";
import ShirtCoat from "./body/effigy/ShirtCoat.js";
import Killer from "./body/effigy/Killer.js";
import Dress from "./body/effigy/Dress.js";
import GymShirt from "./body/effigy/GymShirt.js";
import SportyTee from "./body/effigy/SportyTee.js";
import Hoodie from "./body/effigy/Hoodie.js";
import PoloSweater from "./body/effigy/PoloSweater.js";
import Computer from "./body/effigy/Computer.js";
import TeeArmsCrossed from "./body/effigy/TeeArmsCrossed.js";
import Tee from "./body/effigy/Tee.js";

const bodyComponents = {
    SweaterDots,
    Paper,
    Whatever,
    ThunderTee,
    Coffee,
    PolkadotJacket,
    ButtonPocketShirt,
    StripedTee,
    StrippedPocketTee,
    PointingUp,
    TeeSelena,
    Jacket,
    Explaining,
    ButtonShirt,
    Gaming,
    BlazerBlackTee,
    Turtleneck,
    ShirtCoat,
    Killer,
    Dress,
    GymShirt,
    SportyTee,
    Hoodie,
    PoloSweater,
    Computer,
    TeeArmsCrossed,
    Tee,
};

import SmileTeeth from "./face/SmileTeeth.js";
import AngryFang from "./face/AngryFang.js";
import LoveGrinTeeth from "./face/LoveGrinTeeth.js";
import Hectic from "./face/Hectic.js";
import Old from "./face/Old.js";
import ConcernedFear from "./face/ConcernedFear.js";
import CalmWithMask from "./face/CalmWithMask.js";
import Cheeky from "./face/Cheeky.js";
import SmileWithMask from "./face/SmileWithMask.js";
import EatingHappy from "./face/EatingHappy.js";
import Blank from "./face/Blank.js";
import ExplainingFace from "./face/ExplainingFace.js";
import Serious from "./face/Serious.js";
import LoveGrinTongue from "./face/LoveGrinTongue.js";
import Awe from "./face/Awe.js";
import Cyclops from "./face/Cyclops.js";
import Solemn from "./face/Solemn.js";
import Driven from "./face/Driven.js";
import Tired from "./face/Tired.js";
import EyesClosed from "./face/EyesClosed.js";
import CheersWithMask from "./face/CheersWithMask.js";
import Calm from "./face/Calm.js";
import Angry from "./face/Angry.js";
import Fear from "./face/Fear.js";
import BigSmile from "./face/BigSmile.js";
import Contempt from "./face/Contempt.js";
import Cute from "./face/Cute.js";
import Concerned from "./face/Concerned.js";
import Rage from "./face/Rage.js";
import Suspicious from "./face/Suspicious.js";
import Smile from "./face/Smile.js";
import Monster from "./face/Monster.js";
import SmileLOL from "./face/SmileLOL.js";

const faceComponents = {
    SmileTeeth,
    AngryFang,
    LoveGrinTeeth,
    Hectic,
    Old,
    ConcernedFear,
    CalmWithMask,
    Cheeky,
    SmileWithMask,
    EatingHappy,
    Blank,
    ExplainingFace,
    Serious,
    LoveGrinTongue,
    Awe,
    Cyclops,
    Solemn,
    Driven,
    Tired,
    EyesClosed,
    CheersWithMask,
    Calm,
    Angry,
    Fear,
    BigSmile,
    Contempt,
    Cute,
    Concerned,
    Rage,
    Suspicious,
    Smile,
    Monster,
    SmileLOL,
};

import GrayShort from "./head/GrayShort.js";
import Cornrows from "./head/Cornrows.js";
import Shaved from "./head/Shaved.js";
import HatHip from "./head/HatHip.js";
import Bangs from "./head/Bangs.js";
import DreadsTwo from "./head/DreadsTwo.js";
import Bun from "./head/Bun.js";
import ShortThree from "./head/ShortThree.js";
import MediumBangsColor from "./head/MediumBangsColor.js";
import LongCurly from "./head/LongCurly.js";
import MediumOne from "./head/MediumOne.js";
import ColorMedium from "./head/ColorMedium.js";
import Mohawk from "./head/Mohawk.js";
import MediumStraight from "./head/MediumStraight.js";
import NoHairThree from "./head/NoHairThree.js";
import DocThree from "./head/DocThree.js";
import ShortOne from "./head/ShortOne.js";
import ShavedOne from "./head/ShavedOne.js";
import DocOne from "./head/DocOne.js";
import Pomp from "./head/Pomp.js";
import Beanie from "./head/Beanie.js";
import ShortFour from "./head/ShortFour.js";
import Turban from "./head/Turban.js";
import LongHair from "./head/LongHair.js";
import Wavy from "./head/Wavy.js";
import FlatTopLong from "./head/FlatTopLong.js";
import FlatTop from "./head/FlatTop.js";
import Hijab from "./head/Hijab.js";
import DocTwo from "./head/DocTwo.js";
import ShortTwo from "./head/ShortTwo.js";
import BunClip from "./head/BunClip.js";
import MediumThree from "./head/MediumThree.js";
import TwistsTwo from "./head/TwistsTwo.js";
import MediumBangs from "./head/MediumBangs.js";
import Twists from "./head/Twists.js";
import BangsTwo from "./head/BangsTwo.js";
import ShavedThree from "./head/ShavedThree.js";
import ColorBun from "./head/ColorBun.js";
import Afro from "./head/Afro.js";
import MediumBangsOne from "./head/MediumBangsOne.js";
import ShortFive from "./head/ShortFive.js";
import BantuKnots from "./head/BantuKnots.js";
import MohawkKnots from "./head/MohawkKnots.js";
import BunTwo from "./head/BunTwo.js";
import Bald from "./head/Bald.js";
import NoHairTwo from "./head/NoHairTwo.js";
import DreadsOne from "./head/DreadsOne.js";
import LongBangs from "./head/LongBangs.js";
import BunKnots from "./head/BunKnots.js";
import Bear from "./head/Bear.js";
import LongAfro from "./head/LongAfro.js";
import CornrowsLight from "./head/CornrowsLight.js";
import MediumTwo from "./head/MediumTwo.js";

const headComponents = {
    GrayShort,
    Cornrows,
    Shaved,
    HatHip,
    Bangs,
    DreadsTwo,
    Bun,
    ShortThree,
    MediumBangsColor,
    LongCurly,
    MediumOne,
    ColorMedium,
    Mohawk,
    MediumStraight,
    NoHairThree,
    DocThree,
    ShortOne,
    ShavedOne,
    DocOne,
    Pomp,
    Beanie,
    ShortFour,
    Turban,
    LongHair,
    Wavy,
    FlatTopLong,
    FlatTop,
    Hijab,
    DocTwo,
    ShortTwo,
    BunClip,
    MediumThree,
    TwistsTwo,
    MediumBangs,
    Twists,
    BangsTwo,
    ShavedThree,
    ColorBun,
    Afro,
    MediumBangsOne,
    ShortFive,
    BantuKnots,
    MohawkKnots,
    BunTwo,
    Bald,
    NoHairTwo,
    DreadsOne,
    LongBangs,
    BunKnots,
    Bear,
    LongAfro,
    CornrowsLight,
    MediumTwo,
};

type PieceDetails = {
    type: string;
    options: { [key: string]: string };
};


export const Effigy = ({ style, body, head, face, beard, mask, accessory }: { body: PieceDetails; head: PieceDetails; face: PieceDetails; beard?: PieceDetails; mask?: PieceDetails; accessory?: PieceDetails; style?: React.CSSProperties }) => {
    return (
        <svg style={style} xmlns='http://www.w3.org/2000/svg' viewBox='184.21621621621625 210.7874999999999 940.2702702702704 1130.5875' overflow='visible' width='940.2702702702704px' height='1130.5875px'>
            <g id='Bust'>
                <g id='Body' transform='translate(147, 639) scale(1 1)'>
                    {createBody(body)}
                </g>
                {createHead(
                    body,
                    <>
                        <g id='Hair' transform='translate(342, 190) scale(1 1)'>
                            {createHair(head)}
                        </g>
                        <g id='Face' transform='translate(531, 366) scale(1 1)'>
                            {createFace(face)}
                        </g>
                        <g id='Beard' transform='translate(495, 518) scale(1 1)'>
                            {createBeard(beard)}
                        </g>
                        <g id='Mask' transform='translate(400, 480) scale(1 1)'>
                            {/* {createMask(mask)} */}
                        </g>
                        <g id='Accessories' transform='translate(419, 421) scale(1 1)'>
                            {createAccessory(accessory)}
                        </g>
                    </>
                )}
            </g>
        </svg>
    );
};



const createHair = (head: PieceDetails) => {
    if (!head) {
        return null;
    }
    const HairComponent = headComponents[head.type];
    if (!HairComponent) {
        throw new Error(`Unknown head type: ${head.type}`);
    }

    const hairElement = (
        <HairComponent
            outlineColor={head.options.outlineColor}
            skinColor={head.options.skinColor}
            hairColor={head.options.hairColor}
        />
    );

    const transformMap: { [key: string]: string } = {
        Bald: 'translate(50 20)',
        BangsTwo: 'translate(20 0)',
        BantuKnots: 'translate(30 0)',
        Bear: 'translate(20 0)',
        Bun: 'translate(-20 -30)',
        Beanie: 'translate(20 0)',
        BunClip: 'translate(20 -70)',
        BunKnots: 'translate(10 -30)',
        BunTwo: 'translate(-50 -90)',
        ColorMedium: 'translate(30 0)',
        CornrowsLight: 'translate(-15 0)',
        DreadsTwo: 'translate(0 -15)',
        FlatTop: 'translate(50 0)',
        FlatTopLong: 'translate(50 0)',
        GrayShort: 'translate(40 0)',
        HatHip: 'translate(-30 0)',
        Hijab: 'translate(50 20)',
        LongAfro: 'translate(-100 -130)',
        LongBangs: 'translate(-25 0)',
        LongCurly: 'translate(-50 -40)',
        LongHair: 'translate(-50 0)',
        MediumBangs: 'translate(-50 0)',
        MediumBangsColor: 'translate(-20 0)',
        MediumBangsOne: 'translate(40 10)',
        MediumOne: 'translate(40 10)',
        MediumStraight: 'translate(40 0)',
        MediumTwo: 'translate(-20 0)',
        Mohawk: 'translate(40 0)',
        MohawkKnots: 'translate(40 0)',
        NoHairThree: 'translate(30 0)',
        NoHairTwo: 'translate(30 0)',
        Pomp: 'translate(40 0)',
        Shaved: 'translate(40 0)',
        ShavedOne: 'translate(40 0)',
        ShavedThree: 'translate(10 -20)',
        ShortFive: 'translate(40 -10)',
        ShortFour: 'translate(40 -10)',
        ShortOne: 'translate(40 -10)',
        ShortThree: 'translate(40 0)',
        ShortTwo: 'translate(40 0)',
        Turban: 'translate(40 0)',
        Twists: 'translate(40 0)',
        TwistsTwo: 'translate(40 0)',
        Wavy: 'translate(40 0)'
    };

    const transform = transformMap[head.type] || '';

    return transform ? <g transform={transform}>{hairElement}</g> : hairElement;
};


const createFace = (face: PieceDetails) => {
    const FaceComponent = faceComponents[face.type];
    if (!FaceComponent) {
        throw new Error(`Unknown face type: ${face.type}`);
    }
    return <FaceComponent outlineColor = {face.options.outlineColor} />;
};

const createBody = (body: PieceDetails) => {
    const BodyComponent = bodyComponents[body.type];
    if (!BodyComponent) {
        throw new Error(`Unknown body type: ${body.type}`);
    }
    return <BodyComponent skinColor = {body.options.skinColor} topColor = {body.options.topColor} blazerColor = {body.options.blazerColor}  outlineColor = {body.options.outlineColor} />;
};

const createBeard = (beard: PieceDetails | undefined) => {
    if (!beard) {
        return null;
    }
    if (beard.type === "None") {
        return null;
    }

    const BeardComponent = beardComponents[beard.type];
    if (!BeardComponent) {
        throw new Error(`Unknown beard type: ${beard.type}`);
    }

    const beardElement = (
        <BeardComponent
            outlineColor={beard.options.outlineColor}
            beardColor={beard.options.beardColor}
        />
    );

    const transformMap: { [key: string]: string } = {
        FullColor: 'translate(0 -20) scale(0.94 0.97)',
        FullMax: 'translate(0 -20) scale(0.98 1)',
        FullMedium: 'translate(0 -10) scale(0.98 1)',
        MustacheBull: 'translate(0 -20)',
        MustacheEight: 'translate(-240 -75)'
    };

    const transform = transformMap[beard.type] || '';

    return transform ? <g transform={transform}>{beardElement}</g> : beardElement;
};


const createAccessory = (accessory: PieceDetails | undefined) => {
    if (!accessory) {
        return null;
    }
    if (accessory.type === "None") {
        return null;
    }

    const AccessoryComponent = accessoryComponents[accessory.type];
    if (!AccessoryComponent) {
        throw new Error(`Unknown accessory type: ${accessory.type}`);
    }

    const accessoryElement = (
        <AccessoryComponent
            outlineColor={accessory.options.outlineColor}
            frameColor={accessory.options.frameColor}
        />
    );

    const transformMap: { [key: string]: string } = {
        Eyepatch: 'translate(0 -60)',
        SunglassesTwo: 'translate(-10 0)'
    };

    const transform = transformMap[accessory.type] || '';

    return transform ? <g transform={transform}>{accessoryElement}</g> : accessoryElement;
};




const createHead = (body: PieceDetails, children: React.ReactNode) => {
    const group = (
        <g id='Head' transform='translate(0 0)'>
            {children}
        </g>
    );
    switch (body.type) {
        case "Explaining":
        case "PointingUp":
            return <g transform='translate(0 30)'>{group}</g>;
        case "PolkadotJacket":
            return <g transform='translate(0 20)'>{group}</g>;
        case "Killer":
            return <g transform='translate(0 10)'>{group}</g>;
        case "StrippedPocketTee":
            return <g transform='translate(250 0)'>{group}</g>;
        case "Whatever":
            return <g transform='translate(90 0)'>{group}</g>;
        default:
            return group;
    }
};
import React from "react";

export const GoateeFullConfig = {
    name: "Goatee Full",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 280,
    height: 230,
    viewBox: "0 0 280 230"
};

const GoateeFull = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M237.595 65.8793C240.335 68.6893 242.965 71.6993 245.445 74.8093C246.135 79.7493 246.295 84.7493 245.665 89.9093C244.745 97.3893 242.705 104.699 239.945 111.719C238.315 111.489 236.705 111.029 235.135 110.309C230.155 108.019 227.375 103.409 224.005 99.3393C217.295 91.2493 205.055 86.4693 196.495 94.8593C192.775 98.5093 190.395 103.559 187.075 107.569C183.425 111.979 179.325 116.169 175.045 119.969C174.515 120.449 173.965 120.969 173.395 121.479C171.015 118.759 168.675 116.019 166.385 113.239C159.575 104.959 153.695 95.7893 150.605 85.4493C149.625 82.1693 149.015 78.7493 148.775 75.2993C151.805 71.5393 155.265 68.1193 159.195 65.2893C171.045 56.7393 184.695 55.3493 198.895 54.6093C213.585 53.8493 226.815 54.8493 237.595 65.8793ZM273.435 105.549C272.865 99.8293 271.985 93.8793 270.685 87.9393C265.935 65.8993 255.645 43.9293 235.015 33.8593C225.255 29.0893 214.165 27.4093 203.645 29.7093C185.185 22.7993 164.685 29.8493 150.155 42.4893C133.015 57.3993 125.105 80.7993 122.445 102.719C120.595 117.969 119.898 138.344 124.225 149.409C128.552 160.474 156.051 190.063 195.305 190.269C251.215 190.562 264.805 152.859 266.455 149.559C273.345 135.839 274.945 120.689 273.435 105.549Z'
                fill={outlineColor}
            />
        </>
    );
};

GoateeFull.config = GoateeFullConfig;
export default GoateeFull;

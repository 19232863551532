import React from "react";

export const MustacheBullConfig = {
    name: "Mustache Bull",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 372,
    height: 249,
    viewBox: "0 0 372 249"
};

const MustacheBull = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M350.983 2.00062C348.863 -1.18938 342.853 0.750617 344.403 4.77062C361.226 42.9426 353.443 72.6326 306.162 67.4396C277.658 65.5886 249.976 40.3836 221.349 52.9256C213.968 58.8546 209.109 59.5896 201.31 53.1736C170.364 39.6326 135.324 63.7706 104.233 67.0306C55.5884 71.8136 29.1314 55.5396 53.7044 5.64062C55.2434 2.25062 50.7734 -2.21938 48.0334 1.26062C14.1024 40.7436 12.3984 93.7566 67.0154 114.211C107.1 127.559 183.759 138.604 211.273 98.3606C228.835 127.421 266.37 129.16 296.544 123.96C369.4 117.235 391.724 60.6266 350.983 2.00062Z'
                fill={outlineColor}
            />
        </>
    );
};

MustacheBull.config = MustacheBullConfig;
export default MustacheBull;

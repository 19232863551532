import React from "react";

export const SunglassesTwoConfig = {
    name: "Sunglasses Two",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 418,
    height: 138,
    viewBox: "0 0 418 138"
};

const SunglassesTwo = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M411.934 13.2313C411.111 13.0943 410.287 12.9653 409.463 12.8443C408.82 12.4793 408.065 12.1983 407.187 12.0293C405.182 11.5203 403.191 11.1773 401.203 10.9923C372.671 4.57131 306.664 13.6883 303.94 38.0363C294.854 41.0693 286.992 38.3533 276.461 38.6233C268.445 5.11831 129.862 9.84731 123.197 30.0473C123.172 30.0403 123.147 30.0343 123.121 30.0263C116.717 28.0443 107.276 28.5223 99.9463 28.2083C84.2953 27.8803 68.6263 29.2753 53.3133 32.5763C24.6383 37.1333 -14.4967 42.4683 5.44632 78.2763C5.45032 78.2823 5.45332 78.2893 5.45832 78.2953L5.46132 78.2923C8.89832 81.8153 14.7313 78.9783 14.9483 74.2093C8.24332 57.2453 23.3873 55.2083 35.0393 50.3333C64.0323 39.9173 93.5503 38.4733 123.536 41.6953C125.792 44.3513 129.215 46.6673 132.833 47.8793C152.882 51.2073 142.316 66.0793 154.989 68.2423C152.661 120.084 215.602 148.382 252.071 114.303C266.632 100.067 270.103 78.9783 273.479 59.9693C275.264 59.5683 276.399 58.5923 277.062 57.3003C281.355 53.0143 284.052 49.0563 292.541 47.8793C301.03 46.7033 307.565 49.0643 308.449 49.7873C308.672 50.2363 308.911 50.6863 309.189 51.1363C309.577 51.8073 310.105 52.3423 310.705 52.7633C302.322 104.387 342.399 137.035 382.858 110.26C400.438 93.7233 404.153 68.4053 404.887 45.8073C407.27 44.3693 408.779 41.5823 407.954 38.0783C406.817 33.2483 409.483 29.0663 413.729 26.9663C419.246 24.2393 418.27 14.2803 411.934 13.2313ZM238.087 37.7224C233.065 36.6178 230.92 44.3606 235.958 45.4682C239.526 46.2525 242.549 48.0516 244.592 51.0994L244.807 51.4307C247.548 55.7926 254.48 51.7661 251.719 47.376C248.614 42.4357 243.788 38.9755 238.087 37.7224ZM385.173 32.9216C383.403 31.6745 380.723 32.6003 379.696 34.3626C378.564 36.3031 379.306 38.4637 380.964 39.7332L381.133 39.8571C382.654 40.9291 384.135 42.8505 384.321 44.8193L384.337 45.0655C384.536 50.2177 392.544 50.2438 392.344 45.0655C392.148 39.9997 389.215 35.7673 385.173 32.9216Z'
                fill={outlineColor}
            />
        </>
    );
};

SunglassesTwo.config = SunglassesTwoConfig;

export default SunglassesTwo;

import React from "react";

export const EyepatchConfig = {
    name: "Eye Patch",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 392,
    height: 198,
    viewBox: "0 0 392 198"
};

const Eyepatch = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M335.396 0.386937C341.176 -1.59406 345.786 4.41694 344.676 9.65594C343.366 15.8259 336.926 20.7779 332.656 25.1269C328.176 29.7069 323.606 34.1969 318.866 38.5169C309.816 46.7779 300.436 54.5569 290.546 61.8069L287.522 64.0228C278.456 70.6558 269.417 77.1349 259.706 82.8769C254.446 85.9769 249.136 88.9769 243.756 91.8669C241.837 92.8969 239.496 94.4859 237.087 95.6669C245.896 102.467 252.676 112.847 254.146 122.517C257.786 146.537 242.306 168.797 220.465 177.647C209.576 182.057 197.456 183.337 185.816 182.007C173.976 180.656 161.976 177.156 154.286 167.457C147.456 158.847 144.996 147.858 144.506 137.067C144.276 131.947 144.326 126.906 145.406 122.377C140.696 122.417 135.986 122.397 131.316 122.447C119.776 122.557 108.215 122.608 96.6662 122.287C84.7562 121.947 72.9062 121.417 61.0362 120.358C55.8262 119.887 50.6462 119.117 45.4652 118.377C40.4362 117.667 34.8862 117.397 30.1862 115.337C29.8662 115.207 29.9989 114.747 30.2762 114.656C35.2862 113.037 40.9762 113.326 46.1962 113.007L51.2839 112.698C55.5215 112.445 59.7562 112.212 64.0062 112.076C73.8237 111.753 83.6336 111.674 93.4491 111.505L97.6562 111.427C109.206 111.197 120.756 111.067 132.306 110.697C137.096 110.547 141.896 110.267 146.706 110.007C147.266 108.736 148.087 107.576 148.876 106.447C151.236 103.067 154.236 100.087 157.346 97.3869C163.715 91.8259 170.886 87.6969 178.886 84.9269C186.814 82.1913 198.409 80.8342 206.796 81.0107C215.183 81.1873 227.135 82.3703 230.976 89.2269C231.376 89.9369 238.686 88.5669 240.856 86.9059C245.926 83.0369 250.906 79.0569 255.795 74.9769C264.876 67.3869 274.215 59.9169 282.816 51.7969C291.686 43.4169 300.486 34.8969 308.926 26.0969C313.166 21.6869 317.396 17.2669 321.506 12.7469C325.706 8.13694 329.316 2.45694 335.396 0.386937Z'
                fill={outlineColor}
            />
        </>
    );
};

Eyepatch.config = EyepatchConfig;

export default Eyepatch;

import React from "react";

export const FullConfig = {
    name: "Full",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 280,
    height: 230,
    viewBox: "0 0 280 230"
};

const Full = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M117.725 95.193C125.821 76.2258 143.492 62.2859 163.836 57.1894C178.777 53.4564 194.456 53.5756 209.686 55.0572C217.665 55.828 226.224 56.4597 233.744 59.4228C240.165 61.9545 245.398 66.7396 249.333 72.3566C254.017 79.0127 257.202 86.6309 259.439 94.4392C259.757 95.5494 260.056 96.6676 260.334 97.7918C259.362 103.683 257.296 108.697 251.84 112.401C246.515 116.018 240.161 117.498 233.827 118.023C233.206 117.725 232.606 117.419 232.056 117.104C222.186 111.454 215.239 98.1021 202.782 99.9091C191.665 101.523 184.346 115.511 177.735 123.714C156.513 126.186 135.307 119.809 118.04 107.674C117.163 103.521 117.113 99.3325 117.725 95.193ZM274.758 80.8047C273.06 76.0406 270.922 71.4266 268.388 67.08C260.009 52.7056 247.146 41.2524 231.876 34.7054C195.754 19.2389 149.016 30.1415 119.845 55.5076C109.88 64.1789 99.8046 76.9215 96.7576 90.6282C95.8478 90.8083 94.989 91.2819 94.2819 91.9486C73.2059 78.6794 51.5017 65.9128 34.5183 47.5191C22.1847 34.1548 13.6061 18.3389 8.35307 0.959366C7.92364 -0.471162 5.6167 -0.261938 5.68661 1.32075C5.70658 1.69115 5.72656 2.06054 5.74653 2.43094C5.52682 1.75021 4.4882 2.10059 4.40831 2.76129C1.58206 23.8738 -1.95324 46.2578 1.30244 67.4804C3.24985 80.144 9.0022 89.4149 16.692 99.4256C24.931 110.156 34.2586 120.137 43.796 129.708C49.8479 135.783 56.1695 142.791 63.5797 147.246C72.1982 152.421 81.9753 156.356 91.173 160.35C100.531 164.424 110.028 168.188 119.705 171.451C139.049 177.998 159.043 182.523 179.386 184.505C195.674 186.087 214.289 187.81 229.899 181.682C241.863 176.987 251.52 168.499 259.18 158.287C267.519 147.166 274.111 134.497 277.755 121.138C281.542 107.261 275.556 83.0421 274.758 80.8047Z'
                fill={outlineColor}
            />
        </>
    );
};

Full.config = FullConfig;
export default Full;

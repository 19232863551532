import React from "react";

export const MustacheSevenConfig = {
    name: "Mustache Seven",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 285,
    height: 230,
    viewBox: "0 0 285 230"
};

const MustacheSeven = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M258.635 38.6644C252.041 37.4254 245.224 37.7734 238.674 36.3314C232.284 34.9234 227.209 30.7364 221.035 28.7874C215.602 27.0714 209.234 28.2334 205.003 31.5594C201.072 29.7424 196.605 29.3494 192.133 29.9494C185.351 30.8594 179.023 33.7294 172.982 36.8334C172.282 37.1934 171.581 37.5364 170.881 37.8834C159.719 37.6044 148.276 37.6264 137.323 40.0184C127.624 42.1374 118.455 47.0694 112.671 55.3074C106.198 64.5284 105.029 76.2704 104.031 87.1664C103.396 94.0824 113.002 95.4854 116.257 90.4774C119.632 85.2854 123.145 80.0814 127.642 75.7794C132.75 70.8924 138.963 68.9684 145.926 68.5224C161.294 67.5384 176.764 68.3634 192.147 67.6824C201.064 67.2874 210.992 63.8584 214.586 56.1064C218.422 62.1174 227.114 65.6914 234.263 66.2334C241.818 66.8074 249.489 65.4344 256.969 67.0084C264.115 68.5124 272.609 73.8344 271.02 82.3464C270.086 87.3464 276.407 90.2944 279.316 85.8444C291.195 67.6614 279.49 42.5854 258.635 38.6644Z'
                fill={outlineColor}
            />
        </>
    );
};

MustacheSeven.config = MustacheSevenConfig;
export default MustacheSeven;

import React from "react";

export const MustacheSixConfig = {
    name: "Mustache Six",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 286,
    height: 230,
    viewBox: "0 0 286 230"
};

const MustacheSix = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M258.53 37.2405C249.479 33.0085 239.751 30.4895 229.757 29.5965C225.435 29.2095 216.945 27.9885 212.348 30.8075C210.198 32.1275 208.381 33.9715 206.94 36.2235C206.378 35.4465 205.638 34.7845 205.17 33.9445C202.653 29.4225 187.205 29.3285 182.541 29.7125C171.481 30.6225 160.716 33.2195 150.578 37.5855C132.377 45.4255 117.087 61.7145 121.895 82.3945C122.575 85.3235 125.856 86.4625 128.559 85.9565C146.778 82.5415 164.997 79.1255 183.217 75.7105C187.446 74.9185 191.676 74.1255 195.905 73.3325C199.73 72.6155 204.418 72.3885 208.048 70.7385C209.508 71.3625 211.134 71.7585 212.87 72.1025C234.791 76.4565 256.74 80.6805 278.675 84.9695C281.305 85.4845 284.755 84.1505 285.227 81.2365C288.375 61.7925 275.992 45.4045 258.53 37.2405Z'
                fill={outlineColor}
            />
        </>
    );
};

MustacheSix.config = MustacheSixConfig;
export default MustacheSix;

import React from "react";

export const MustacheEightConfig = {
    name: "Mustache Eight",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 817,
    height: 297,
    viewBox: "0 0 817 297"
};

const MustacheEight = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M741.333 8.49433C725.335 23.0873 735.613 59.6453 741.851 49.7623C742.781 40.6103 745.639 23.0743 754.915 19.5263C823.145 16.6163 809.055 185.321 681.957 164.557C600.977 159.093 515.446 41.8173 439.294 113.185C356.554 45.7883 263.127 146.828 177.924 160.666C108.649 183.069 5.12546 141.931 23.7685 56.5303C28.4255 31.4703 63.0815 2.80333 84.3065 28.2353C90.5765 34.2763 82.6115 56.4013 89.2035 51.8273C99.4655 40.4903 96.7915 22.3853 87.3075 11.0943C71.5995 -7.60367 43.5995 0.576333 27.3115 13.7813C-46.1715 86.0723 42.3345 195.195 130.912 187.986C227.978 199.858 359.98 64.9623 426.701 130.494C433.505 137.176 446.29 137.179 452.205 130.499C508.929 66.4453 618.404 197.357 697.481 188.008C781.582 195.668 848.891 94.1633 800.349 22.3943C788.255 4.14833 759.191 -9.60367 741.333 8.49433Z'
                fill={outlineColor}
            />
        </>
    );
};

MustacheEight.config = MustacheEightConfig;
export default MustacheEight;

import React from "react";

export const ChinConfig = {
    name: "Chin",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 283,
    height: 245,
    viewBox: "0 0 283 245"
};

const Chin = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M212.56 153.064C212.56 153.064 168.17 159.587 148.3 150.368C128.49 141.178 109.22 132.286 91.2503 120.114C72.8403 107.642 57.2203 92.8328 46.0703 73.8757C35.7003 56.237 27.0803 37.5205 18.4003 18.9484C17.8931 17.8631 12.9103 7.0738 11.1503 3.45561C8.44034 -2.15452 0.52034 -0.393546 0.52034 8.31515C0.52034 17.0238 0.13034 82.9754 15.3503 108.919C29.0803 132.35 52.3503 148.722 75.4703 163.012C101.05 178.813 135.03 195.894 165.55 200.407C193.17 204.487 224.69 193.026 244.69 174.136C265.6 154.381 271.52 127.542 282.31 101.839C284.72 96.0941 276.177 92.6294 273.67 98.2793C268.951 108.919 212.56 153.064 212.56 153.064Z'
                fill={outlineColor}
            />
        </>
    );
};

Chin.config = ChinConfig;

export default Chin;

import React from "react";

export const MustacheYosemiteConfig = {
    name: "Mustache Yosemite",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 299,
    height: 230,
    viewBox: "0 0 299 230"
};

const MustacheYosemite = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M279.528 190.863C281.547 192.778 285.93 196.993 290.129 198.846H294.056C294.899 198.688 295.727 198.447 296.549 198.175C297.618 195.552 297.824 192.496 297.299 189.418C294.331 172.372 291.68 155.111 293.093 137.762C294.424 121.405 299.132 105.128 297.861 88.6151C296.088 49.6481 258.461 22.6651 225.52 30.5541C221.267 30.9311 217.108 32.1281 213.404 34.2561C162.018 12.7001 98.5068 49.1221 110.469 108.704C112.762 124.814 116.46 140.093 115.324 156.512C114.78 164.37 113.529 172.142 112.061 179.874C110.959 185.676 108.042 192.83 110.722 198.557C121.488 205.356 132.712 191.226 139.903 179.099C143.667 168.907 146.794 158.472 150.32 148.201C156.682 129.74 158.156 119.544 179.151 114.51C188.219 111.417 196.313 106.857 203.254 100.845C208.589 98.2901 211.63 93.1721 213.448 87.9971C214.354 90.5451 213.101 93.6381 212.874 96.2831C228.101 116.465 246.944 112.644 253.723 126.678C258.91 139.889 261.825 153.973 265.794 167.643C266.337 169.514 276.384 187.881 279.528 190.863Z'
                fill={outlineColor}
            />
        </>
    );
};

MustacheYosemite.config = MustacheYosemiteConfig;
export default MustacheYosemite;

import React from "react";

export const MediumStraightConfig = {
    name: "Medium Straight",
    props: [
        {
            name: "outlineColor"
        },
        {
            name: "skinColor"
        }
    ],
    width: 482,
    height: 567,
    viewBox: "0 0 482 567"
};

const MediumStraight = ({ outlineColor = "#000", skinColor = "#D08B5B" }) => {
    return (
        <>
            <path
                d='M415.277 307.815C413.593 229.234 394.014 142.24 326.899 93.6798C322.624 90.5828 316.208 92.0828 312.665 95.6198C309.756 96.1878 307.084 97.9498 305.307 101.19C265.62 173.625 223.841 244.915 179.989 314.907C151.833 360.163 117.631 405.476 113.119 460.331C112.338 461.368 105.089 469.552 109.313 468.956C111.188 467.975 113.097 467.169 115.105 466.573C120.467 474.39 132.918 473.161 135.878 464.338C146.14 464.633 156.296 467.255 166.073 470.297C190.054 477.803 214.427 485.223 238.901 490.96C319.527 512.358 406.161 485.133 412.603 391.135C421.257 378.942 416.575 324.599 415.277 307.815Z'
                fill={skinColor}
            />
            <path
                d='M473.939 500.959C462.529 447.399 455.21 393.149 448.66 338.829C445.49 312.519 442.96 286.14 440.029 259.81C437.29 235.279 434.099 210.669 427.699 186.789C421.75 164.599 413.049 142.629 400.229 123.47C388.21 105.509 373.099 90.1189 355.75 77.2689C321.469 51.8789 277.629 38.3689 234.99 42.8489C228.549 43.5289 222.169 44.6189 215.859 46.0789C217.869 45.4889 219.9 44.9379 221.949 44.4589C222.609 44.2999 222.316 43.4109 221.669 43.4699C48.1946 59.2309 7.38863 360.919 3.67862 421.749C3.38862 426.48 3.15963 431.199 2.98962 435.929C1.68862 446.48 0.718625 457.06 0.258625 467.669C-0.260375 479.909 -0.010375 492.23 1.11862 504.429C2.27863 516.938 3.59862 529.759 8.81962 541.259C12.0186 548.339 161.219 538.64 162.759 530.279C165.879 513.399 167.479 495.98 166.5 478.81C166.04 470.579 165.189 462.409 163.429 454.349C162.949 452.149 162.439 448.019 161.15 444.749C174.759 418.89 189.729 393.769 205.139 368.939C237.21 317.239 271.309 266.499 297.519 211.438C310.66 183.849 323.609 154.55 329.809 124.319L330.809 124.519C330.689 124.879 330.769 125.329 331.129 125.579C359.619 145.269 378.66 175.109 391 207.06C405.369 244.259 412.339 284.429 411.339 324.289C409.75 387.629 385.74 465.909 317.809 485.929C302.309 490.499 285.57 491.789 269.689 488.449C262.199 486.869 254.859 484.438 248.099 480.839C240.599 476.849 234.439 471.199 227.41 466.55C220.979 462.3 213.389 470.589 217.089 476.879C225.259 490.779 242.839 499.279 257.939 503.459C274.049 507.929 291.04 508.14 307.449 505.3C308.699 505.089 309.939 504.849 311.169 504.589C311.449 508.709 313.219 512.81 316.099 516.14C317.389 517.64 318.979 518.938 320.759 520.029C320.99 524.05 323.58 528.089 327.79 528.97C350.279 533.649 443.679 521.379 466.139 514.688C472.169 512.89 475.219 506.98 473.939 500.959Z'
                fill={outlineColor}
            />
        </>
    );
};

MediumStraight.config = MediumStraightConfig;
export default MediumStraight;

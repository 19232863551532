import React from "react";

export const GoateeConfig = {
    name: "Goatee",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 280,
    height: 230,
    viewBox: "0 0 280 230"
};

const Goatee = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M207.914 31.3282C196.793 26.3192 176.823 30.6902 169.573 32.4282C152.718 36.4702 135.762 45.2052 130.522 58.8902C124.617 74.3132 136.399 79.1682 138.132 78.3752C149.8 79.1972 163.695 71.8622 175.922 69.9692C185.848 68.4192 196.319 67.0662 205.619 61.7922C212.409 65.5272 227.745 67.5462 240.122 70.8122C250.663 73.5922 255.23 79.6212 265.002 78.3392C276.714 76.8032 271.92 52.1702 258.867 42.1912C244.581 31.2702 220.419 26.8412 207.914 31.3282ZM229.934 136.653C220.205 139.026 219.551 117.7 218.461 110.166C217.61 104.281 212.807 89.1015 200.631 89.7565C188.455 90.4114 183.879 103.129 184.496 111.038C184.897 116.168 189.623 142.15 174.536 145.436C164.917 145.402 153.789 144.753 143.675 142.888C131.771 140.192 116.292 130.753 112.203 149.342C110.732 156.033 163.169 175.909 186.438 177.707C210.037 182.49 237.647 175.136 254.448 157.084C258.27 152.977 269.036 135.93 269.803 131.753C271.415 122.978 267.683 117.332 256.825 120.424C249.155 124.52 236.191 135.128 229.934 136.653Z'
                fill={outlineColor}
            />
        </>
    );
};

Goatee.config = GoateeConfig;
export default Goatee;

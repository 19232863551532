import React from "react";

export const GlassesFiveConfig = {
    name: "Glasses Five",
    props: [
        {
            name: "outlineColor"
        }
    ],
    width: 399,
    height: 138,
    viewBox: "0 0 399 138"
};

const GlassesFive = ({ outlineColor = "#000" }) => {
    return (
        <>
            <path
                d='M352.985 106.395C337.977 113.623 317.039 102.962 306.948 91.7954C303.275 87.7294 300.441 76.0964 301.854 68.7184C305.681 48.7374 320.061 36.7454 335.242 32.6504C356.286 26.9724 377.464 41.3564 374.995 58.3304C372.654 74.4174 369.107 97.7524 352.985 106.395ZM250.834 68.0134C249.631 89.7884 232.144 108.847 211.244 114.494C177.57 123.89 160.928 100.219 153.39 74.7184C152.641 72.1844 150.88 64.4504 155.605 57.8164C174.188 31.7214 219.401 36.1694 241.983 43.3334C248.593 49.6544 251.295 58.7774 250.834 68.0134ZM369.291 30.4564C369.281 30.4694 369.269 30.4804 369.259 30.4934C369.182 30.4464 369.106 30.3984 369.027 30.3524C369.114 30.3884 369.206 30.4194 369.291 30.4564ZM397.465 32.9734C395.509 10.8034 325.946 19.2924 317.813 21.1094C295.617 27.6374 276.446 45.3974 251.63 37.5904C225.917 8.92742 139.328 17.1474 121.176 31.7484C117.74 30.4004 113.562 31.1234 109.135 32.3954C86.4011 31.5744 -17.3829 39.4114 3.84509 68.4264C7.06709 70.6614 10.2861 68.3874 10.3751 63.7834C121.038 23.8364 110.311 61.2534 123.435 56.8834C125.439 57.4604 127.53 57.8254 129.359 58.2094C139.517 61.4774 142.966 60.4674 144.304 71.8704C153.497 159.921 270.752 129.531 261.135 57.3074C270.657 56.7834 278.986 52.6664 289.055 56.6864C296.046 124.723 350.797 127.469 367.274 103.884C379.699 87.5144 374.772 62.8104 391.851 49.9324C398.593 55.5744 399.027 36.5324 397.465 32.9734Z'
                fill={outlineColor}
            />
        </>
    );
};

GlassesFive.config = GlassesFiveConfig;

export default GlassesFive;
